<template>
  <div class="chat">
    채팅
    <div class="list">
      <div
        class="message"
        v-for="message in messageList"
        :key="message.messageId">
        <div class="sender">
          {{message._sender.nickname}} :
        </div>
        <div class="content">
          {{message.message}}
        </div>
      </div>
    </div>
    <div class="chatbox">
      <form @submit.prevent="submit">
        <div class="nickname">
          <input type="text" v-model="nickname" @change="updateNickname">
        </div>
        <div class="inputbox"><input type="text" class="input" v-model='message' /></div>
        <div class="btn"><input type="submit" value="보내기"></div>
      </form>
    </div>
  </div>
</template>
<style scoped>
  .chat {
    width: 720px;
    margin: auto;
  }
  .list {
    width: 100%;
  }
  .chatbox {
    width: 100%;
    margin-bottom: 200px;
  }

  .chatbox > form > .nickname {
    width: 10%;
    float: left;
  }
  .chatbox > form > .inputbox {
    width: 80%;
    float: left
  }
  .chatbox > form  > .inputbox > input {
    width: 100%;
  }
  .chatbox > form  > .btn {
    width: 10%;
    float: left
  }
  .chatbox > form  > .btn > input {
    width: 100%;
  }
  .message {
    text-align: left;
    clear: both;
    overflow: hidden;
  }
  .message > .sender {
    float: left;
  }
  .message > .content {
    float: left;
  }
</style>
<script>
import * as SendBird from 'sendbird';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'chat',
  data() {
    return {
      sb: null,
      OpenChannel: null,
      message: '',
      messageList: [],
      nickname: 'NONAME',
      channelHandler: null
    };
  },
  mounted() {
    this.init();
    this.connect()
      .then(this.getChannel)
      .then(this.joinChannel)
      .then(this.loadPreviousMessage)
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    init() {
      this.sb = new SendBird({ appId: 'E43EF627-D012-4B22-BED5-4C5E75208A0B' });
      this.channelHandler = new this.sb.ChannelHandler();
      this.channelHandler.onMessageReceived = this.onMessageReceived;
      this.channelHandler.onMessageUpdated = this.onMessageUpdated;
      /*

      channelHandler.onMessageDeleted = function(channel, messageId) {};
      channelHandler.onMentionReceived = function(channel, message) {};
      channelHandler.onChannelChanged = function(channel) {};
      channelHandler.onChannelDeleted = function(channelUrl, channelType) {};
      channelHandler.onChannelFrozen = function(channel) {};
      channelHandler.onChannelUnfrozen = function(channel) {};
      channelHandler.onMetaDataCreated = function(channel, metaData) {};
      channelHandler.onMetaDataUpdated = function(channel, metaData) {};
      channelHandler.onMetaDataDeleted = function(channel, metaDataKeys) {};
      channelHandler.onMetaCountersCreated = function(channel, metaCounter) {};
      channelHandler.onMetaCountersUpdated = function(channel, metaCounter) {};
      channelHandler.onMetaCountersDeleted = function(channel, metaCounterKeys) {};
      channelHandler.onChannelHidden = function(groupChannel) {};
      channelHandler.onUserReceivedInvitation = function(groupChannel, inviter, invitees) {};
      channelHandler.onUserDeclinedInvitation = function(groupChannel, inviter, invitee) {};
      channelHandler.onUserJoined = function(groupChannel, user) {};
      channelHandler.onUserLeft = function(groupChannel, user) {};
      channelHandler.onDeliveryReceiptUpdated = function(groupChannel) {};
      channelHandler.onReadReceiptUpdated = function(groupChannel) {};
      channelHandler.onTypingStatusUpdated = function(groupChannel) {};
      channelHandler.onUserEntered = function(openChannel, user) {};
      channelHandler.onUserExited = function(openChannel, user) {};
      channelHandler.onUserMuted = function(channel, user) {};
      channelHandler.onUserUnmuted = function(channel, user) {};
      channelHandler.onUserBanned = function(channel, user) {};
      channelHandler.onUserUnbanned = function(channel, user) {};
      */
      this.sb.addChannelHandler('UNIQUE_HANDLER_ID', this.channelHandler);
    },
    connect() {
      let chatid = this.$cookie.get('chatid');
      if (!chatid) {
        chatid = uuidv4();
        this.$cookie.set('chatid', chatid, { expires: 7 });
      }
      return this.sb.connect(chatid)
        .then((user) => {
          // console.log('연결성공', user);
          this.nickname = user.nickname;
          return this.updateCurrentUserInfo(this.nickname, null);
        });
    },
    updateCurrentUserInfo(nickname, profile) {
      return this.sb.updateCurrentUserInfo(nickname, profile);
    },
    getChannel() {
      // console.log('채널확인');
      const CHANNEL_URL = 'sendbird_open_channel_1169_2e21de839d7d6a8c606261e93ccc682ae6d0889a';
      return this.sb.OpenChannel.getChannel(CHANNEL_URL);
    },
    joinChannel(channel) {
      this.OpenChannel = channel;
      // console.log('채널입장', this.OpenChannel);
      return this.OpenChannel.enter();
    },
    sendMessage(msg) {
      const params = new this.sb.UserMessageParams();
      if (msg === '') return;
      params.message = msg;
      params.customType = '';
      params.data = '';
      this.OpenChannel.sendUserMessage(params, this.setNewMessage);
    },
    loadPreviousMessage() {
      const messageListQuery = this.OpenChannel.createPreviousMessageListQuery();
      return messageListQuery.load(10, false).then(this.setMessageList);
    },
    setMessageList(messageList) {
      // console.log(messageList);
      this.messageList = messageList;
    },
    setNewMessage(message) {
      this.messageList.splice(0, 1);
      this.messageList.push(message);
    },
    onMessageReceived(channel, message) {
      // console.log(channel, message);
      this.setNewMessage(message);
    },
    onMessageUpdated(channel, message) {
      console.log(channel, message);
      this.loadPreviousMessage();
    },
    submit() {
      this.sendMessage(this.message);
      this.message = '';
      return false;
    },
    updateNickname() {
      return this.updateCurrentUserInfo(this.nickname, null);
    }
  }
};
</script>
