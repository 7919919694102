<template>
  <div>
    <video-player :options="videoOptions" class="video"></video-player>
    <chat />
  </div>
</template>

<style scoped="scss">
  .video {
    max-width: 100%;
    margin: auto;
  }
</style>

<script>
// @ is an alias to /src
import VideoPlayer from '@/components/video.vue';
import Chat from '@/components/chat.vue';

export default {
  name: 'Home',
  components: {
    VideoPlayer,
    Chat
  },
  data() {
    return {
      videoOptions: {
        width: '1080px',
        autoplay: true,
        controls: true,
        liveui: true,
        plugins: {
          videoJsResolutionSwitcher: {
            default: 'high',
            dynamicLabel: true
          }
        },
        sources: [
          {
            src: 'https://d2hbcz3jrdwxr.cloudfront.net/out/v1/e2df738995414de087e1dc4dc86ecbd4/index.m3u8',
            label: '1080P',
            res: 1080
          },
          {
            src: 'https://d7b30omg1li4r.cloudfront.net/main_720p.m3u8',
            label: '720P',
            res: 720
          },
          {
            src: 'https://d7b30omg1li4r.cloudfront.net/main_480p.m3u8',
            label: '480P',
            res: 480
          },
          {
            src: 'https://d2hbcz3jrdwxr.cloudfront.net/out/v1/e2df738995414de087e1dc4dc86ecbd4/index.m3u8',
            label: 'HLS-mp'
          },
          {
            src: 'https://d2hbcz3jrdwxr.cloudfront.net/out/v1/711fd0f2657a486e8beb81be9aae99e9/index.mpd',
            label: 'DASH-mp',
            type: 'application/dash+xml',
          },
        ]
      }
    };
  }
};
</script>
