import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/1080',
    name: '1080',
    component: Home,
  },
  {
    path: '/720',
    name: '720',
    component: () => import(/* webpackChunkName: "about" */ '../views/720.vue'),
  },
  {
    path: '/480',
    name: '480',
    component: () => import(/* webpackChunkName: "about" */ '../views/480.vue'),
  },
  {
    path: '/drm',
    name: 'drm',
    component: () => import(/* webpackChunkName: "about" */ '../views/drm.vue'),
  },
  {
    path: '/hls',
    name: 'hls',
    component: () => import(/* webpackChunkName: "about" */ '../views/hls.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
