<template>
    <div>
        <video
          playsinline
          controls
          autoplay
          preload="auto"
          ref="videoPlayer"
          class="video-js vjs-default-skin">
        </video>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import 'video.js/dist/video-js.css';
import '@xiaoyexiang/videojs-resolution-switcher-v7/lib/videojs-resolution-switcher-v7.css';
import videojs from 'video.js';

window.videojs = videojs;

export default {
  name: 'hlsvideo',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null
    };
  },
  mounted() {
    import('videojs-contrib-dash')
      .then(import('@xiaoyexiang/videojs-resolution-switcher-v7'))
      .then(() => {
        this.player = videojs(this.$refs.videoPlayer, this.options);
      });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
<style >
  .video-js {
    max-width: 600px;
    margin: auto;
  }
  .vjs-resolution-button .vjs-resolution-button-label {
    line-height: 2em;
  }
  .vjs-resolution-button .vjs-menu {
    bottom: 1.5em;
    margin-bottom: 0;
  }
</style>
